angular.module("BlogModule").controller("blog.show", ($scope, $http, $routeParams, $location, $sce, getApiData, videoParse, obtenerMes,$window) => {


    $scope.textoFormateado = "";
    $scope.mes = "";
    $scope.post = {};
    $scope.texto = "";
    $scope.subtitulo = "";
    $scope.parrafos = [];
    $scope.color = "indigo";
    $scope.imagen = false;
    $scope.comentario = {
        autor: "",
        mensaje: "",
        pubdate: null,
        ubicacion: "privada"
    };
    $scope.thisurl = $location.absUrl();
    $scope.title = "blog";
    const colorCatego = () => getApiData.obtenerInfoCategorias().then(
        ({
            data
        }) => {
            data.forEach(v => {
                if (
                    v.titulo.toLowerCase() == $scope.post.categoria.categoria.toLowerCase()
                ) {
                    $scope.color = v.color;
                    $scope.imagen = v.img;
                }
            });
        }
    );
    // theGet();

    getApiData.obtenerPost($routeParams.id)
        .then(
            response => {
                $scope.post = response.data;

                $scope.texto = $scope.post.texto;
                $scope.textoFormateado = $sce.trustAsHtml($scope.post.texto);
                let inicioParrafo = $scope.texto.indexOf('<p');
                let finParrafo = $scope.texto.indexOf('</p>');
                // $scope.parrafos = $scope.texto.split('\n');
                $scope.subtitulo = $sce.trustAsHtml($scope.texto.substring(inicioParrafo, finParrafo));

                $scope.mes = obtenerMes.mes(new Date($scope.post.pubdate).getMonth());

                $scope.video = videoParse.sofparser($scope.post.video);
                $scope.video = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + $scope.video);
                colorCatego();
            },
            error => {
                // PAGINA 404
                $window.location.href="/";
                console.log(error);
            }
        );
        $scope.social = {
            facebook: "https://www.facebook.com/sharer/sharer.php?u=" + window.encodeURIComponent($location.absUrl()),
            shareFacebook: () => {
                // console.log($scope.social.facebook);

            },
            twitter: "https://twitter.com/home?status=" +window.encodeURIComponent("'"+$scope.post.titulo+"'"+" Mirá el post de Pablo Iachetti en el blog de su web: ")+ window.encodeURIComponent($location.absUrl()),
            shareTwitter: () => {
                // console.log($scope.social.twitter);
            }
        }
    $scope.botonenviocomentario=true;
    $scope.submit = (event) => {
        $scope.botonenviocomentario=false;
        $scope.comentario.autor = $scope.comentario.autor === '' ? 'Anonimo' : $scope.comentario.autor;
        $scope.comentario.pubdate = new Date;
        getApiData.agregarComentario($scope.comentario, $routeParams.id)
            .then(
                response => {
                    // console.log(response);
                    $scope.post.comentarios = response.data.comentarios;
                },
                error => {
                    console.log(error);
                }
            );

    }

    /* let theGet = () => {
        getApiData.obtenerPost($routeParams.id)
        .then(
            response => {
                $scope.post = response.data;
                $scope.texto = $scope.post.texto;
                $scope.parrafos = $scope.texto.split('\n');
                $scope.subtitulo = $scope.parrafos[0];
            },
            error => {
                console.log(error);
            }
        );
    } */

});
