angular.module("BlogModule", []).controller("blog.index", ($scope, $http, getApiData, $window, $sce, videoParse, $routeParams) => {
  $scope.blogIndex = true;
  //    
  $scope.title = "blog";
  $scope.posteos = [];
  $scope.blogs = [];
  $scope.blogss = [];
  $scope.blogsSinFiltrar = [];
  $scope.inicial = true;
  $scope.categoria = false;
  $scope.tag = false;
  $scope.quantity = 8;
  $scope.indice = 0;
  $scope.cantPaginas = 0;
  $scope.pagina = 0;
  //

  //
  
  if ($routeParams.categoria) {

    $scope.categoria = $routeParams.categoria;
    $http.get('/api/posts/categoria/lista/' + $scope.categoria).then(
      ({
        data
      }) => {
        $scope.blogs = data;
        $scope.blogs.map(posteo => {
          posteo.video = videoParse.sofparser(posteo.video);
        });
        calcularCantidadDePaginas();
      }, error => {
        console.error(error);
      }
    );
    //


  } else 
    if ($routeParams.tag) {
      let tag = $routeParams.tag;
      $http.get('api/posts/tags/' + tag)
        .then(
          ({
            data
          }) => {
            $scope.posts = data;
            $scope.posts.map(posteo => {
              posteo.video = videoParse.sofparser(posteo.video);
            });
            calcularCantidadDePaginas();
          }, error => {
            console.error(error);

          }
        )

    } else
    $http.get("/api/posts")
    .then(
      ({
        data
      }) => {
        $scope.blogsSinFiltrar = data;
        $scope.blogsSinFiltrar.map(posteo => {
          posteo.video = videoParse.sofparser(posteo.video);
        });
        calcularCantidadDePaginas();

      }, error => {
        console.error(error);

      }
    );

    // Promise.all([
    //   $http.get('/api/categorias')
    //   .then(
    //     ({
    //       data
    //     }) => {
    //       $scope.categorias = data;

    //     },
    //     error => {
    //       console.error(error);

    //     }
    //   ),
    //   $http.get("/api/posts")
    //   .then(
    //     ({
    //       data
    //     }) => {
    //       $scope.posteos = data;

    //     }, error => {
    //       console.error(error);

    //     }
    //   )
    // ]).then(
    //   res => {

    //     $scope.categorias.map((categoria, i, a) => {
    //       categoria.pubdate = null;
    //       categoria.posteo = null
    //       $scope.posteos.map(posteo => {
    //         if (posteo.categoria._id === categoria._id) {
    //           categoria.posteo = posteo;
    //           if (categoria.pubdate) {
    //             if (new Date(posteo.pubdate) > new Date(categoria.pubdate)) {
    //               categoria.pubdate = posteo.pubdate
    //               categoria.titulo = posteo.titulo
    //               categoria.pubdate = posteo.pubdate
    //               categoria.blog = posteo._id;
    //               categoria.posteo = posteo;
    //               if (posteo.video) {
    //                 posteo.video = videoParse.sofparser(posteo.video);
    //               }
    //               posteo.leermas = $sce.trustAsHtml(posteo.texto.substring(0, 91));
    //             }
    //           } else {
    //             categoria.pubdate = posteo.pubdate
    //             categoria.titulo = posteo.titulo
    //             categoria.pubdate = posteo.pubdate
    //             categoria.blog = posteo._id;
    //             categoria.posteo = posteo;
    //             if (posteo.video) {
    //               posteo.video = videoParse.sofparser(posteo.video);
    //             }
    //             posteo.leermas = $sce.trustAsHtml(posteo.texto.substring(0, 91));
    //           }
    //         }
    //       })
    //       $scope.blogss.push(categoria.posteo);

    //     });
    //     $scope.$apply();
    //   }, error => {
    //     console.error
    //   }
    // );

  
  //
  let calcularCantidadDePaginas=()=>{
    $scope.cantPaginas = Math.ceil($scope.posts.length !== 0 ? $scope.posts.length / $scope.quantity : 0);
  }
  let aplicarPagina = () => {
    $scope.indice = $scope.pagina * $scope.quantity;
  };
  //
  $scope.paginaMenos = () => {
    calcularCantidadDePaginas();
    if ($scope.pagina > 0)
      $scope.pagina -= 1;
    else
      $scope.pagina = 0;
    aplicarPagina();
  }
  //
  $scope.paginaMas = () => {
    calcularCantidadDePaginas();
    if ($scope.pagina + 1 < $scope.cantPaginas)
      $scope.pagina += 1;
    aplicarPagina();
  };
  //
  $scope.irABlogShow = (id) => {
    $window.location.href = "/blog/" + id;
  };
});