angular.module("EventosModule").controller("eventos.show", ($scope, $rootScope,$timeout, $http, $routeParams, getApiData, obtenerMes) => {

    $scope.inscribirse=false;
    $scope.enviar=true;
    $scope.loading=false;
    $scope.mes="";
    $scope.title = "evento";
    $scope.mail={};

    let id=$routeParams.id;
    $scope.evento={};

    getApiData.obtenerEvento(id).then(
        response=>{
            $scope.evento=response.data;
            $scope.mes=obtenerMes.mes(new Date($scope.evento.fecha).getMonth());
        },
        error=>{
            console.log(error);
        }
    );

    $scope.enviarMail=()=>{
        $scope.enviar=false;
        $scope.loading=true;
        $scope.mail.evento=$scope.evento.titulo;
        $http
        .post("/api/mailing/inscripcion", {
            nombre: $scope.mail.nombre,
            // telefono: $scope.mail.telefono,
            email: $scope.mail.email,
            evento:$scope.mail.evento
            // consulta: $scope.mail.consulta
          })
          .then(
            response => {
              $scope.enviar = false;
              $scope.loading = true;
              $scope.mail.envioCorrecto = true;
              $rootScope.snackbar('Email enviado');
            },
            error => {
              $scope.enviar = false;
              $scope.loading = true;
              $scope.mail.envioCorrecto = false;
              console.error(error);
              $rootScope.snackbar('Hubo un error');
            }
          );
        $timeout(function(){
            $scope.inscribirse=false;
            $scope.enviar=true;
            $scope.loading=false;
        },3000);
        // console.log($scope.mail.nombre.$valid);
    };

    $scope.completarFormulario=()=>{
        $scope.inscribirse=true;
    };

    $scope.cancelar=()=>{
        $scope.inscribirse=false;
        $scope.enviar=true;
        $scope.loading=false;
    };

});
