angular
  .module("HomeModule")
  .directive("newsletters", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/newsletters.html"
    };
  })
  .directive("mandarForm", $timeout => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        elm.bind("click", e => {
          if (attrs.mandarForm) {
            $timeout(() => {
              scope.libro.enviar(attrs.mandarForm);
            });
          }
        });
      }
    };
  })
  .directive("homeCabecera", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-cabecera.html"
    };
  })
  .directive("homeLibro", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-libro.html"
    };
  })
  .directive("homeBlog", () => {
    return {
      restrict: "E",
      templateUrl: "/views/home/home-blog.html"
    };
  });
