angular.module("MainApp").config(($httpProvider, $routeProvider, $locationProvider) => {

  $httpProvider.defaults.headers.common["X-Requested-With"] =
    "XMLHttpRequest";

  $locationProvider.html5Mode(true);

  $locationProvider.hashPrefix("");

  $routeProvider
    .when("/", {
      templateUrl: "/views/home/index.html",
      controller: "home.index"
    })
    .when("/sobre", {
      templateUrl: "/views/sobre/index.html",
      controller: "sobre.index"
    })
    .when("/blog", {
      templateUrl: "/views/blog/index.html",
      controller: "blog.index"
    })
    .when("/blog/:id", {
      templateUrl: "/views/blog/show.html",
      controller: "blog.show"
    })
    .when("/blog/tags/:tag", {
      templateUrl: "/views/blog/index.html",
      controller: "blog.index"
    })
    .when("/blog/categoria/:categoria", {
      templateUrl: "/views/blog/index.html",
      controller: "blog.index"
    })
    .when("/videos", {
      templateUrl: "/views/videos/index.html",
      controller: "videos.index"
    })
    .when("/eventos", {
      templateUrl: "/views/eventos/index.html",
      controller: "eventos.index"
    })
    .when("/eventos/:id", {
      templateUrl: "/views/eventos/show.html",
      controller: "eventos.show"
    })


    .when("/login", {
      templateUrl: "/views/auth/login.html",
      controller: "auth.login"
    })
    .when("/logout", {
      templateUrl: "views/home/index.html",
      resolve: {
        secureAccess: (auth, $window) => {
          if (!auth.isLoggedIn()) {
            $window.location.href = "/";
            $window.location.reload();
          }
        }
      },
      controller: "auth.logout"
    })



    .otherwise({
      templateUrl: "/views/home/index.html",
      redirectTo: "/"
    });
});
