angular.module("SobreModule", []).controller("sobre.index", ($scope,$sce, $http,videoParse) => {


    $scope.title = "sobre";
    $scope.posteos = [];
    $scope.indice = 0;
    $http.get('/api/posts').then(
        ({
            data
        }) => {
            $scope.posteos = data;
            $scope.posteos.map(posteo => {
                posteo.video = videoParse.sofparser(posteo.video);
                posteo.video = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + posteo.video);

            })
        },
        error => {
            console.log(error);
        }
    );
   
});
