"use strict";

angular.module("MainApp", ["ngRoute", "ngTouch", "HomeModule", "AuthModule", "BlogModule", "EventosModule", "SobreModule", "VideosModule", "angular-google-analytics"]).config(function (AnalyticsProvider) {
  AnalyticsProvider.setAccount("UA-126805658-1"); //EJ UA-3123123-2
}).controller("mainController", function ($location, $scope, $rootScope, $timeout, $window, auth, Analytics) {
  $rootScope.snackbar = function (mensaje) {
    $rootScope.mensaje = mensaje;
    var snack = angular.element(document.querySelector("#snackbar"));
    snack.addClass("show");
    $timeout(function () {
      snack.removeClass("show");
    }, 3000);
  };
  $rootScope.isMobile = $window.innerWidth <= 992;
  $rootScope.showMenuNavbar = !$rootScope.isMobile;
  $rootScope.toggleMenuNavbarMobile = function () {
    $rootScope.showMenuNavbar = !$rootScope.showMenuNavbar;
  };
  $rootScope.closeMenuNavbarMobile = function () {
    if ($rootScope.isMobile) $rootScope.showMenuNavbar = false;
  };
  angular.element($window).bind("scroll", function () {
    if ($rootScope.isMobile) $rootScope.showMenuNavbar = false;
  });
  angular.element($window).bind("resize", function () {
    $rootScope.isMobile = $window.innerWidth <= 992;
    $rootScope.$$phase || $rootScope.$apply();
  });

  $rootScope.$on("$routeChangeSuccess", function () {
    var bodyClass = "";
    Analytics.trackPage($location.$$path);
    var path = $location.$$path.split("/");

    path.map(function (tramo) {
      if (tramo !== "") {
        bodyClass += "-" + tramo;
      }
    });

    $rootScope.bodyClass = bodyClass !== "" ? "page" + bodyClass : "page-home";
  });
}).filter("precio", function () {
  return function (input) {
    if (input) {
      return parseFloat(input).toFixed(2);
    }
    return "";
  };
}).filter("encodeurl", function () {
  return function (input) {
    if (input) {
      return window.encodeURIComponent(input);
    }
    return "";
  };
}).filter("removeTrailingSlash", function () {
  return function (input) {
    if (input) {
      if (input[0] === "/") return input.slice(1);else return input;
    }
    return "";
  };
});
"use strict";

angular.module("MainApp").config(function ($httpProvider, $routeProvider, $locationProvider) {

  $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

  $locationProvider.html5Mode(true);

  $locationProvider.hashPrefix("");

  $routeProvider.when("/", {
    templateUrl: "/views/home/index.html",
    controller: "home.index"
  }).when("/sobre", {
    templateUrl: "/views/sobre/index.html",
    controller: "sobre.index"
  }).when("/blog", {
    templateUrl: "/views/blog/index.html",
    controller: "blog.index"
  }).when("/blog/:id", {
    templateUrl: "/views/blog/show.html",
    controller: "blog.show"
  }).when("/blog/tags/:tag", {
    templateUrl: "/views/blog/index.html",
    controller: "blog.index"
  }).when("/blog/categoria/:categoria", {
    templateUrl: "/views/blog/index.html",
    controller: "blog.index"
  }).when("/videos", {
    templateUrl: "/views/videos/index.html",
    controller: "videos.index"
  }).when("/eventos", {
    templateUrl: "/views/eventos/index.html",
    controller: "eventos.index"
  }).when("/eventos/:id", {
    templateUrl: "/views/eventos/show.html",
    controller: "eventos.show"
  }).when("/login", {
    templateUrl: "/views/auth/login.html",
    controller: "auth.login"
  }).when("/logout", {
    templateUrl: "views/home/index.html",
    resolve: {
      secureAccess: function secureAccess(auth, $window) {
        if (!auth.isLoggedIn()) {
          $window.location.href = "/";
          $window.location.reload();
        }
      }
    },
    controller: "auth.logout"
  }).otherwise({
    templateUrl: "/views/home/index.html",
    redirectTo: "/"
  });
});
"use strict";

angular.module("MainApp").directive("mainMenu", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/main-menu.html",
    controller: "general.main-menu"
  };
}).directive("footerSection", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/footer-section.html",
    controller: "general.footer"
  };
}).directive("subscribeNewsletter", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/subscribe-newsletter.html",
    controller: "general.newsletter"
  };
}).directive("subscribeEvents", function () {
  return {
    restrict: "E",
    templateUrl: "/views/general/subscribe-events.html",
    controller: "general.events"
  };
}).directive("blogContent", function () {
  return {
    restrict: "E",
    scope: {
      posts: "="
    },
    templateUrl: "/views/blog/content.html",
    controller: "blog.index"
  };
}).directive("blogCard", function () {
  return {
    restrict: "E",
    scope: {
      post: "="
    },
    templateUrl: "/views/blog/card.html",
    controller: "blog.index"
  };
}).directive("loadFrame", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $("document").ready(function () {
        $(elm).attr("src", attrs.source);
      });
    }
  };
}).directive("scrollRight", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(attrs.target).animate({
          scrollLeft: "+=" + $(attrs.target).width()
        }, parseInt(attrs.time));
      });
    }
  };
}).directive("scrollLeft", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $(attrs.target).animate({
          scrollLeft: "-=" + $(attrs.target).width()
        }, parseInt(attrs.time));
      });
    }
  };
}).directive("scrollToContacto", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("body, html").animate({
          scrollTop: $("footer-section").offset().top
        }, 500);
      });
    }
  };
}).directive("scrollToTarget", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("body, html").animate({
          scrollTop: $(attrs.target).offset().top
        }, 700);
      });
    }
  };
}).directive("escondeMenu", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(elm).on("click", function () {
        $("#navbar").collapse("hide");
      });
    }
  };
}).directive("flotante", function () {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      $(window).on("scroll", function (e) {
        if ($(window).scrollTop() > 92) {
          if ($(window).width() >= 992) {
            $(elm).addClass("flotante");
          }
        } else {
          $(elm).removeClass("flotante");
        }
      });
    }
  };
});
/**.directive("flotante", ($rootScope, $timeout) => {
  return {
    restrict: "A",
    link: (scope, elm, attrs) => {
      $(window).on("scroll", e => {
        if ($(window).scrollTop() < 50) {
          if ($(window).width() >= 992) {
            $(elm).addClass("enlarged");
            elm[0].children[0].src = "assets/images/environmental-brand-logo.png";
            angular.element(elm[0].children[0]).removeClass('brand-logo');
          }
        } else {
          $(elm).removeClass("enlarged");
          elm[0].children[0].src = "assets/images/environmental-brand-logo-shrinked.png";
          angular.element(elm[0].children[0]).addClass('brand-logo');
        }
      });
    }
  };
}) */
"use strict";

angular.module("AuthModule", []);
"use strict";

angular.module("EventosModule", []).controller("eventos.index", function ($scope, $http, getApiData) {

    $scope.title = "eventos";
    $scope.eventos = [];
    getApiData.listaDeEventos().then(function (response) {
        $scope.eventos = response.data;
    }, function (error) {
        console.log(error);
    });
});
"use strict";

angular.module("BlogModule", []).controller("blog.index", function ($scope, $http, getApiData, $window, $sce, videoParse, $routeParams) {
  $scope.blogIndex = true;
  //    
  $scope.title = "blog";
  $scope.posteos = [];
  $scope.blogs = [];
  $scope.blogss = [];
  $scope.blogsSinFiltrar = [];
  $scope.inicial = true;
  $scope.categoria = false;
  $scope.tag = false;
  $scope.quantity = 8;
  $scope.indice = 0;
  $scope.cantPaginas = 0;
  $scope.pagina = 0;
  //

  //

  if ($routeParams.categoria) {

    $scope.categoria = $routeParams.categoria;
    $http.get('/api/posts/categoria/lista/' + $scope.categoria).then(function (_ref) {
      var data = _ref.data;

      $scope.blogs = data;
      $scope.blogs.map(function (posteo) {
        posteo.video = videoParse.sofparser(posteo.video);
      });
      calcularCantidadDePaginas();
    }, function (error) {
      console.error(error);
    });
    //

  } else if ($routeParams.tag) {
    var tag = $routeParams.tag;
    $http.get('api/posts/tags/' + tag).then(function (_ref2) {
      var data = _ref2.data;

      $scope.posts = data;
      $scope.posts.map(function (posteo) {
        posteo.video = videoParse.sofparser(posteo.video);
      });
      calcularCantidadDePaginas();
    }, function (error) {
      console.error(error);
    });
  } else $http.get("/api/posts").then(function (_ref3) {
    var data = _ref3.data;

    $scope.blogsSinFiltrar = data;
    $scope.blogsSinFiltrar.map(function (posteo) {
      posteo.video = videoParse.sofparser(posteo.video);
    });
    calcularCantidadDePaginas();
  }, function (error) {
    console.error(error);
  });

  // Promise.all([
  //   $http.get('/api/categorias')
  //   .then(
  //     ({
  //       data
  //     }) => {
  //       $scope.categorias = data;

  //     },
  //     error => {
  //       console.error(error);

  //     }
  //   ),
  //   $http.get("/api/posts")
  //   .then(
  //     ({
  //       data
  //     }) => {
  //       $scope.posteos = data;

  //     }, error => {
  //       console.error(error);

  //     }
  //   )
  // ]).then(
  //   res => {

  //     $scope.categorias.map((categoria, i, a) => {
  //       categoria.pubdate = null;
  //       categoria.posteo = null
  //       $scope.posteos.map(posteo => {
  //         if (posteo.categoria._id === categoria._id) {
  //           categoria.posteo = posteo;
  //           if (categoria.pubdate) {
  //             if (new Date(posteo.pubdate) > new Date(categoria.pubdate)) {
  //               categoria.pubdate = posteo.pubdate
  //               categoria.titulo = posteo.titulo
  //               categoria.pubdate = posteo.pubdate
  //               categoria.blog = posteo._id;
  //               categoria.posteo = posteo;
  //               if (posteo.video) {
  //                 posteo.video = videoParse.sofparser(posteo.video);
  //               }
  //               posteo.leermas = $sce.trustAsHtml(posteo.texto.substring(0, 91));
  //             }
  //           } else {
  //             categoria.pubdate = posteo.pubdate
  //             categoria.titulo = posteo.titulo
  //             categoria.pubdate = posteo.pubdate
  //             categoria.blog = posteo._id;
  //             categoria.posteo = posteo;
  //             if (posteo.video) {
  //               posteo.video = videoParse.sofparser(posteo.video);
  //             }
  //             posteo.leermas = $sce.trustAsHtml(posteo.texto.substring(0, 91));
  //           }
  //         }
  //       })
  //       $scope.blogss.push(categoria.posteo);

  //     });
  //     $scope.$apply();
  //   }, error => {
  //     console.error
  //   }
  // );


  //
  var calcularCantidadDePaginas = function calcularCantidadDePaginas() {
    $scope.cantPaginas = Math.ceil($scope.posts.length !== 0 ? $scope.posts.length / $scope.quantity : 0);
  };
  var aplicarPagina = function aplicarPagina() {
    $scope.indice = $scope.pagina * $scope.quantity;
  };
  //
  $scope.paginaMenos = function () {
    calcularCantidadDePaginas();
    if ($scope.pagina > 0) $scope.pagina -= 1;else $scope.pagina = 0;
    aplicarPagina();
  };
  //
  $scope.paginaMas = function () {
    calcularCantidadDePaginas();
    if ($scope.pagina + 1 < $scope.cantPaginas) $scope.pagina += 1;
    aplicarPagina();
  };
  //
  $scope.irABlogShow = function (id) {
    $window.location.href = "/blog/" + id;
  };
});
"use strict";

angular.module("SobreModule", []).controller("sobre.index", function ($scope, $sce, $http, videoParse) {

    $scope.title = "sobre";
    $scope.posteos = [];
    $scope.indice = 0;
    $http.get('/api/posts').then(function (_ref) {
        var data = _ref.data;

        $scope.posteos = data;
        $scope.posteos.map(function (posteo) {
            posteo.video = videoParse.sofparser(posteo.video);
            posteo.video = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + posteo.video);
        });
    }, function (error) {
        console.log(error);
    });
});
"use strict";

var debounced = false;

angular.module("HomeModule", []).controller("home.index", function ($scope, $window, $timeout, $http, getApiData, $sce, videoParse, $rootScope) {
  // $scope.linkRetira =
  //   "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-98d4d716-63a6-4a58-9a12-4183563efb14";
  // $scope.linkPagaEnvio =
  //   "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-74fd5ada-693f-4a96-9565-675b805a025a";
  $scope.linkLibroDigital = "https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=103718449-c7b0dd71-aa56-463a-8dd5-c1d12ce499a2";
  $scope.botonMP = false;
  $scope.enviando = false;
  $scope.blogs = [];
  $scope.categorias = {};
  $scope.imagenes = [];
  $scope.posteos = [];

  $scope.envioCorrecto = false;
  $scope.envioFallido = false;

  $scope.locked = false;
  $scope.invalidCode = false;
  $scope.correctCode = false;

  $scope.libro = {
    nombre: "",
    apellido: "",
    edad: "",
    email: "",
    codigoDescuento: "",
    retira: ""
  };
  $scope.newsletters = {
    email: "",
    solicitado: false
  };
  //
  $scope.bloquear = function () {
    $scope.enviando = true;
  };
  //

  //////////

  // $scope.verc = () => {
  //   if (debounced) {
  //     debounced.cancel();
  //   }
  //   if ($scope.libro.codigoDescuento) {
  //     $scope.locked = true;
  //     $scope.invalidCode = false;
  //     $scope.correctCode = false;
  //     debounced = _.debounce(() => {
  //       $http({
  //         method: "POST",
  //         url: "/api/840fc382fe4297c60b813911",
  //         data: {
  //           code: $scope.libro.codigoDescuento
  //         }
  //       }).then(
  //         ({ status }) => {
  //           $timeout(() => {
  //             $scope.locked = false;
  //             $scope.invalidCode = false;
  //             $scope.correctCode = true;
  //             $scope.linkRetira = "http://mpago.la/3MhaE6";
  //             $scope.linkPagaEnvio = "http://mpago.la/3MhaE6";
  //           });
  //         },
  //         error => {
  //           $timeout(() => {
  //             $scope.locked = false;
  //             $scope.invalidCode = true;
  //             $scope.correctCode = false;
  //             $scope.linkRetira =
  //               "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-98d4d716-63a6-4a58-9a12-4183563efb14";
  //             $scope.linkPagaEnvio =
  //               "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-74fd5ada-693f-4a96-9565-675b805a025a";
  //           });
  //         }
  //       );
  //     }, 750);
  //     debounced();
  //   } else {
  //     $scope.locked = false;
  //     $scope.correctCode = false;
  //     $scope.invalidCode = false;
  //     $scope.linkRetira =
  //       "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-98d4d716-63a6-4a58-9a12-4183563efb14";
  //     $scope.linkPagaEnvio =
  //       "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-74fd5ada-693f-4a96-9565-675b805a025a";
  //   }
  // };

  //////////

  $scope.libro.enviar = function (retira) {
    if (retira === "envio" && $scope.libro.domicilio || retira === "local" || retira === "digital") {
      $scope.envioCorrecto = false;
      $scope.envioFallido = false;
      $scope.libro.retira = retira;
      $scope.enviando = true;
      $http.post("/api/mailing/comprar", {
        nombre: $scope.libro.nombre,
        apellido: $scope.libro.apellido,
        edad: $scope.libro.edad,
        email: $scope.libro.email,
        domicilio: $scope.libro.domicilio,
        code: $scope.libro.codigoDescuento,
        retira: $scope.libro.retira
      }).then(function (_ref) {
        var data = _ref.data,
            status = _ref.status;

        if (status === 200) {
          $rootScope.snackbar("Email enviado");
          $scope.enviando = false;
          $scope.envioCorrecto = true;
        }
      }, function (error) {
        $rootScope.snackbar("Hubo un error");
        $scope.enviando = false;
        $scope.envioFallido = true;
      });
    }
  };
  //
  Promise.all([$http.get("/assets/data/categorias.json").then(function (_ref2) {
    var data = _ref2.data;

    $scope.imagenes = data;
  }, function (error) {
    console.error(error);
  }), $http.get("/api/categorias").then(function (_ref3) {
    var data = _ref3.data;

    $scope.categorias = data;
  }, function (error) {
    console.error(error);
  }), $http.get("/api/posts").then(function (_ref4) {
    var data = _ref4.data;

    $scope.posteos = data;
    console.log(data);
  }, function (error) {
    console.error(error);
  })]).then(function (res) {
    $scope.categorias.map(function (categoria, i, a) {
      categoria.img = $scope.imagenes[i].img;
      categoria.color = $scope.imagenes[i].color;
      categoria.pubdate = null;
      categoria.posteo = null;
      $scope.posteos.map(function (posteo) {
        if (posteo.categoria._id === categoria._id) {
          categoria.posteo = posteo;
          if (categoria.pubdate) {
            if (new Date(posteo.pubdate) > new Date(categoria.pubdate)) {
              categoria.pubdate = posteo.pubdate;
              categoria.titulo = posteo.titulo;
              categoria.pubdate = posteo.pubdate;
              categoria.blog = posteo._id;
              categoria.posteo = posteo;
              if (posteo.video) {
                posteo.video = videoParse.sofparser(posteo.video);
              }
            }
          } else {
            categoria.pubdate = posteo.pubdate;
            categoria.titulo = posteo.titulo;
            categoria.pubdate = posteo.pubdate;
            categoria.blog = posteo._id;
            categoria.posteo = posteo;
            if (posteo.video) {
              posteo.video = videoParse.sofparser(posteo.video);
            }
          }
        }
      });
      $scope.blogs.push(categoria.posteo);
    });
    $scope.$apply();
  }, function (error) {
    console.error;
  });
  //
  $scope.colores = ["#008080", "#0000FF", "#880088", "#adff2f", "#ffa500", "#ff0000", "#14AECF", "#ffff00"];

  $scope.newsletters.enviar = function () {
    $http.post("/api/mailing/newsletters", {
      email: $scope.newsletters.email
    }).then(function (response) {
      $scope.newsletters.solicitado = true;
      $scope.newsletters.envioCorrecto = true;
      // console.log(response);
    }, function (error) {
      $scope.newsletters.solicitado = true;
      $scope.newsletters.envioCorrecto = false;
      console.error(error);
    });
  };

  // $timeout(() => {
  //   let videoLibro = document.querySelector("#videolibro");
  //   const mediaSource = new MediaSource();
  //   const url = URL.createObjectURL(mediaSource);
  //   videoLibro.src = url;
  //   $timeout(() => {
  //     const videoSourceBuffer = mediaSource.addSourceBuffer('video/mp4;codecs="H264"');
  //     fetch("/assets/videos/PROMO.mp4").then(function (response) {
  //       // The data has to be a JavaScript ArrayBuffer
  //       return response.arrayBuffer();
  //     }).then(function (videoData) {
  //       videoSourceBuffer.appendBuffer(videoData);
  //     });
  //   }, 1000)
  // }, 100);
});
"use strict";

angular.module("VideosModule", []).controller("videos.index", function ($scope, $http, $sce, videoParse) {
    $scope.quantity = 4;
    $scope.postVideos = [];
    $scope.title = "videos";
    $scope.indice = 0;
    $scope.cantPaginas = 0;

    $http.get('/api/posts').then(function (_ref) {
        var data = _ref.data;

        $scope.postVideos = data;
        $scope.postVideos.map(function (posteo) {
            posteo.video = videoParse.sofparser(posteo.video);
            posteo.video = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + posteo.video);
        });
        // $scope.postVideos.sort(
        //     (post, anterior)=>post.pubdate<anterior
        // )
    }, function (error) {
        console.log(error);
    });
    var aplicarPagina = function aplicarPagina() {
        $scope.indice = $scope.pagina * $scope.quantity;
    };
    //
    $scope.paginaMenos = function () {
        $scope.cantPaginas = Math.ceil($scope.posts.length !== 0 ? $scope.posts.length / $scope.quantity : 0);
        if ($scope.pagina > 0) $scope.pagina -= 1;else $scope.pagina = 0;
        aplicarPagina();
    };
    //
    $scope.paginaMas = function () {
        $scope.cantPaginas = Math.ceil($scope.posts.length !== 0 ? $scope.posts.length / $scope.quantity : 0);
        if ($scope.pagina + 1 < $scope.cantPaginas) $scope.pagina += 1;
        aplicarPagina();
    };
});
"use strict";

angular.module("HomeModule").directive("newsletters", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/newsletters.html"
  };
}).directive("mandarForm", function ($timeout) {
  return {
    restrict: "A",
    link: function link(scope, elm, attrs) {
      elm.bind("click", function (e) {
        if (attrs.mandarForm) {
          $timeout(function () {
            scope.libro.enviar(attrs.mandarForm);
          });
        }
      });
    }
  };
}).directive("homeCabecera", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-cabecera.html"
  };
}).directive("homeLibro", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-libro.html"
  };
}).directive("homeBlog", function () {
  return {
    restrict: "E",
    templateUrl: "/views/home/home-blog.html"
  };
});
"use strict";

angular.module("MainApp").factory("auth", function ($http, $window) {
  var _getToken = function _getToken() {
    return $window.localStorage["pabloiachettisite-token"];
  };

  var secureKey = {
    headers: {
      Authorization: "Bearer " + _getToken()
    }
  };
  var _saveToken = function _saveToken(token) {
    $window.localStorage["pabloiachettisite-token"] = token;
  };
  return {
    saveToken: function saveToken(token) {
      return _saveToken(token);
    },
    getToken: function getToken() {
      return _getToken();
    },
    secureKey: secureKey,
    isLoggedIn: function isLoggedIn() {
      var token = _getToken();
      var payload = void 0;

      if (token) {
        payload = token.split(".")[1];
        payload = $window.atob(payload);
        payload = JSON.parse(payload);

        return payload.exp > Date.now() / 1000;
      } else {
        return false;
      }
    },
    currentUser: function currentUser() {
      if (isLoggedIn()) {
        var token = _getToken();
        var payload = token.split(".")[1];
        payload = $window.atob(payload);
        payload = JSON.parse(payload);
        return { email: payload.email, name: payload.name };
      }
    },
    login: function login(user) {
      return $http.post("/api/usuarios/login", user).then(function (response) {
        _saveToken(response.data.token);
        return response;
      }, function (err) {
        return err;
      });
    },
    logout: function logout() {
      $window.localStorage.removeItem("pabloiachettisite-token");
    }
  };
});
"use strict";

angular.module("MainApp").factory("getApiData", function ($http, $rootScope) {
  var getter = function getter(url) {
    return $http({ method: "GET", url: url });
  };
  var putter = function putter(url, body) {
    return $http.put(url, body);
  };
  var poster = function poster(url, body) {
    return $http.post(url, body);
  };
  return {
    listaDePost: function listaDePost() {
      return getter('/api/posts');
    },
    listaDePostPorTag: function listaDePostPorTag(tag) {
      return getter('api/posts/tags/' + tag);
    },
    listaDePostPorCategoria: function listaDePostPorCategoria(categoria) {
      return getter('api/posts/categoria/lista/' + categoria);
    },
    getUnPostCategoria: function getUnPostCategoria(id) {
      return getter('api/posts/categoria/' + id);
    },
    obtenerPost: function obtenerPost(id) {
      return getter('/api/posts/' + id);
    },
    obtenerCategoria: function obtenerCategoria(id) {
      return getter('/api/categorias/' + id);
    },
    obtenerCategorias: function obtenerCategorias() {
      return getter('api/categorias');
    },
    agregarComentario: function agregarComentario(comentario, id) {
      return putter('/api/posts/comentar/' + id, comentario);
    },
    listaDeEventos: function listaDeEventos() {
      return getter('/api/eventos');
    },
    obtenerEvento: function obtenerEvento(id) {
      return getter('/api/eventos/' + id);
    },
    enviarMailEventos: function enviarMailEventos(email) {
      return poster('/api/mailing/inscripcion', email);
    },
    enviarMailSuscripcion: function enviarMailSuscripcion(email) {
      return poster('/api/mailing/suscripcion', email);
    },
    obtenerInfoCategorias: function obtenerInfoCategorias() {
      return getter('/assets/data/categorias.json');
    }
  };
});
"use strict";

angular.module("MainApp").factory("obtenerMes", function ($window, $rootScope) {
    var obtenerMes = function obtenerMes(numero) {
        var mes = "";
        switch (numero) {
            case 0:
                mes = "Enero";
                break;
            case 1:
                mes = "Febrero";
                break;
            case 2:
                mes = "Marzo";
                break;
            case 3:
                mes = "Abril";
                break;
            case 4:
                mes = "Mayo";
                break;
            case 5:
                mes = "Junio";
                break;
            case 6:
                mes = "Julio";
                break;
            case 7:
                mes = "Agosto";
                break;
            case 8:
                mes = "Septiembre";
                break;
            case 9:
                mes = "Octubre";
                break;
            case 10:
                mes = "Noviembre";
                break;
            case 11:
                mes = "Diciembre";
                break;
        }
        return mes;
    };
    return {
        mes: function mes(numero) {
            return obtenerMes(numero);
        }
    };
});
"use strict";

angular.module("MainApp").factory("storage", function ($window, $rootScope) {
  return {};
});
"use strict";

angular.module("MainApp").factory("videoParse", function () {
    var embedLink = function embedLink(v) {
        return 'https://www.youtube.com/embed/' + v;
    };
    var parse = function parse(url) {
        return url.split('/')[3];
    };
    var youtube_parser = function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    };
    var _parseArray = function _parseArray(array) {
        array.map(function (posteo) {
            posteo.video = videoParse.sofparser(posteo.video);
        });
    };
    return {
        embeded: function embeded(v) {
            return embedLink(v);
        },
        parser: function parser(url) {
            return parse(url);
        },
        sofparser: function sofparser(url) {
            return youtube_parser(url);
        },
        parseArray: function parseArray(array) {
            return _parseArray(array);
        }
    };
});
"use strict";

angular.module("AuthModule").controller("auth.login", function ($scope, $window, auth) {
  if (!auth.isLoggedIn()) {
    $scope.email = "";
    $scope.password = "";
    $scope.failLogin = false;

    $scope.sendForm = function () {
      auth.login({ email: $scope.email, password: $scope.password }).then(function (response) {
        if (response.status === 401) {
          $scope.failLogin = true;
        } else {
          $window.location.href = "/";
          $window.location.reload();
        }
      });
    };
  } else {
    $window.location.href = "/";
    $window.location.reload();
  }
}).controller("auth.logout", function ($scope, $window, auth) {
  auth.logout();
  $window.location.href = "/";
  $window.location.reload();
});
"use strict";

angular.module("EventosModule").controller("eventos.show", function ($scope, $rootScope, $timeout, $http, $routeParams, getApiData, obtenerMes) {

    $scope.inscribirse = false;
    $scope.enviar = true;
    $scope.loading = false;
    $scope.mes = "";
    $scope.title = "evento";
    $scope.mail = {};

    var id = $routeParams.id;
    $scope.evento = {};

    getApiData.obtenerEvento(id).then(function (response) {
        $scope.evento = response.data;
        $scope.mes = obtenerMes.mes(new Date($scope.evento.fecha).getMonth());
    }, function (error) {
        console.log(error);
    });

    $scope.enviarMail = function () {
        $scope.enviar = false;
        $scope.loading = true;
        $scope.mail.evento = $scope.evento.titulo;
        $http.post("/api/mailing/inscripcion", {
            nombre: $scope.mail.nombre,
            // telefono: $scope.mail.telefono,
            email: $scope.mail.email,
            evento: $scope.mail.evento
            // consulta: $scope.mail.consulta
        }).then(function (response) {
            $scope.enviar = false;
            $scope.loading = true;
            $scope.mail.envioCorrecto = true;
            $rootScope.snackbar('Email enviado');
        }, function (error) {
            $scope.enviar = false;
            $scope.loading = true;
            $scope.mail.envioCorrecto = false;
            console.error(error);
            $rootScope.snackbar('Hubo un error');
        });
        $timeout(function () {
            $scope.inscribirse = false;
            $scope.enviar = true;
            $scope.loading = false;
        }, 3000);
        // console.log($scope.mail.nombre.$valid);
    };

    $scope.completarFormulario = function () {
        $scope.inscribirse = true;
    };

    $scope.cancelar = function () {
        $scope.inscribirse = false;
        $scope.enviar = true;
        $scope.loading = false;
    };
});
"use strict";

angular.module("BlogModule").controller("blog.search", function ($scope, $http, $routeParams, getApiData) {
    $scope.title = $routeParams.tag;
});
"use strict";

angular.module("BlogModule").controller("blog.show", function ($scope, $http, $routeParams, $location, $sce, getApiData, videoParse, obtenerMes, $window) {

    $scope.textoFormateado = "";
    $scope.mes = "";
    $scope.post = {};
    $scope.texto = "";
    $scope.subtitulo = "";
    $scope.parrafos = [];
    $scope.color = "indigo";
    $scope.imagen = false;
    $scope.comentario = {
        autor: "",
        mensaje: "",
        pubdate: null,
        ubicacion: "privada"
    };
    $scope.thisurl = $location.absUrl();
    $scope.title = "blog";
    var colorCatego = function colorCatego() {
        return getApiData.obtenerInfoCategorias().then(function (_ref) {
            var data = _ref.data;

            data.forEach(function (v) {
                if (v.titulo.toLowerCase() == $scope.post.categoria.categoria.toLowerCase()) {
                    $scope.color = v.color;
                    $scope.imagen = v.img;
                }
            });
        });
    };
    // theGet();

    getApiData.obtenerPost($routeParams.id).then(function (response) {
        $scope.post = response.data;

        $scope.texto = $scope.post.texto;
        $scope.textoFormateado = $sce.trustAsHtml($scope.post.texto);
        var inicioParrafo = $scope.texto.indexOf('<p');
        var finParrafo = $scope.texto.indexOf('</p>');
        // $scope.parrafos = $scope.texto.split('\n');
        $scope.subtitulo = $sce.trustAsHtml($scope.texto.substring(inicioParrafo, finParrafo));

        $scope.mes = obtenerMes.mes(new Date($scope.post.pubdate).getMonth());

        $scope.video = videoParse.sofparser($scope.post.video);
        $scope.video = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + $scope.video);
        colorCatego();
    }, function (error) {
        // PAGINA 404
        $window.location.href = "/";
        console.log(error);
    });
    $scope.social = {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=" + window.encodeURIComponent($location.absUrl()),
        shareFacebook: function shareFacebook() {
            // console.log($scope.social.facebook);

        },
        twitter: "https://twitter.com/home?status=" + window.encodeURIComponent("'" + $scope.post.titulo + "'" + " Mirá el post de Pablo Iachetti en el blog de su web: ") + window.encodeURIComponent($location.absUrl()),
        shareTwitter: function shareTwitter() {
            // console.log($scope.social.twitter);
        }
    };
    $scope.botonenviocomentario = true;
    $scope.submit = function (event) {
        $scope.botonenviocomentario = false;
        $scope.comentario.autor = $scope.comentario.autor === '' ? 'Anonimo' : $scope.comentario.autor;
        $scope.comentario.pubdate = new Date();
        getApiData.agregarComentario($scope.comentario, $routeParams.id).then(function (response) {
            // console.log(response);
            $scope.post.comentarios = response.data.comentarios;
        }, function (error) {
            console.log(error);
        });
    };

    /* let theGet = () => {
        getApiData.obtenerPost($routeParams.id)
        .then(
            response => {
                $scope.post = response.data;
                $scope.texto = $scope.post.texto;
                $scope.parrafos = $scope.texto.split('\n');
                $scope.subtitulo = $scope.parrafos[0];
            },
            error => {
                console.log(error);
            }
        );
    } */
});
"use strict";

angular.module("MainApp")
/// CONTROLLER NEWSLETTER
.controller("general.newsletter", function ($rootScope, $scope, $http) {
  $scope.enviando = false;
  $scope.listo = false;
  $scope.newsletter = {
    email: ""
  };
  $scope.enviarSubscripcion = function () {
    $scope.enviando = true;
    $rootScope.snackbar('Gracias por subscribirte');

    if ($scope.newsletter != undefined) $http.post('/api/mailing/suscripcion', {
      email: $scope.newsletter.email
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      $rootScope.snackbar('Email enviado');
      $scope.enviando = false;
      $scope.listo = true;
    }, function (error) {
      $rootScope.snackbar('Hubo un error al enviar el mail');
      console.log(error);
      $scope.enviando = false;
    });
    $http.post('/api/email/', {
      email: $scope.newsletter.email
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };
})
/// CONTROLLER EVENTS
.controller("general.events", function ($scope, $rootScope, $http) {
  $scope.enviando = false;
  $scope.newsletter = {
    email: ""
  };
  $scope.enviarSubscripcion = function () {
    $scope.enviando = true;
    // $rootScope.snackbar('Gracias por subscribirte');

    if ($scope.newsletter != undefined) $http.post('/api/mailing/eventos', {
      email: $scope.newsletter.email
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      $rootScope.snackbar('Email enviado');
      // console.log(response);
    }, function (error) {
      console.log(error);
    });
  };
})
/// CONTROLLER MAIN MENU
.controller("general.main-menu", function ($scope, $window, $location) {
  $scope.home = function () {
    $location.path('/');
  };
})
/// CONTROLLER FOOTER
.controller("general.footer", function ($scope, $http, getApiData) {
  $scope.sent = false;
  $scope.formcontacto = {
    nombre: "",
    telefono: "",
    email: "",
    consulta: "",
    solicitado: false
  };
  $scope.enviarr = function () {
    console.log($scope.formcontacto);
  };
  $scope.formcontacto.enviar = function () {
    event.preventDefault();
    $scope.sent = true;

    $http.post("/api/mailing/suscripcion", {
      nombre: $scope.formcontacto.nombre,
      telefono: $scope.formcontacto.telefono,
      email: $scope.formcontacto.email,
      consulta: $scope.formcontacto.consulta
    }).then(function (response) {
      $scope.sent = false;
      $scope.formcontacto.solicitado = true;
      $scope.formcontacto.envioCorrecto = true;
    }, function (error) {
      $scope.sent = false;
      $scope.formcontacto.solicitado = true;
      $scope.formcontacto.envioCorrecto = false;
      console.error(error);
    });
  };
  $scope.color = false;
  $scope.imagen = false;
  $scope.posts = false;
  $scope.evento = false;
  //
  getApiData.listaDePost().then(function (_ref) {
    var data = _ref.data;

    $scope.posts = data;
    // console.log($scope.posts);
    colorCatego();
  }, function (error) {
    console.log(error);
  });
  var auxDataColorCategoria = false;
  //
  var colorCatego = function colorCatego() {
    return getApiData.obtenerInfoCategorias().then(function (_ref2) {
      var data = _ref2.data;

      auxDataColorCategoria = data;
      auxDataColorCategoria.forEach(function (v) {
        if ($scope.posts.categoria && $scope.posts.categoria.categoria && v.titulo.toLowerCase() == $scope.posts.categoria.categoria.toLowerCase()) {
          $scope.color = v.color;
          $scope.imagen = v.img;
        } else {
          // console.error('error foreach');
        }
      });
    }, function (error) {
      console.log(error);
    });
  };
  //
  getApiData.listaDeEventos().then(function (_ref3) {
    var data = _ref3.data;

    $scope.evento = data[0];
  }, function (error) {
    console.error(error);
  });
});