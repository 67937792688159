angular
  .module("MainApp")
  /// CONTROLLER NEWSLETTER
  .controller("general.newsletter", ($rootScope, $scope, $http) => {
    $scope.enviando = false;
    $scope.listo=false;
    $scope.newsletter = {
      email: ""
    }
    $scope.enviarSubscripcion = () => {
      $scope.enviando = true;
      $rootScope.snackbar('Gracias por subscribirte');

      if ($scope.newsletter != undefined)
        $http.post('/api/mailing/suscripcion', {
          email: $scope.newsletter.email
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          $rootScope.snackbar('Email enviado');
          $scope.enviando = false;
          $scope.listo=true;
        }, error => {
          $rootScope.snackbar('Hubo un error al enviar el mail');
          console.log(error);
          $scope.enviando = false;

        });
      $http.post('/api/email/', {
        email: $scope.newsletter.email
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    };
  })
  /// CONTROLLER EVENTS
  .controller("general.events", ($scope, $rootScope, $http) => {
    $scope.enviando = false;
    $scope.newsletter = {
      email: ""
    }
    $scope.enviarSubscripcion = () => {
      $scope.enviando = true;
      // $rootScope.snackbar('Gracias por subscribirte');

      if ($scope.newsletter != undefined)
        $http.post('/api/mailing/eventos', {
          email: $scope.newsletter.email
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          $rootScope.snackbar('Email enviado');
          // console.log(response);
        }, error => {
          console.log(error);
        });

    };
  })
  /// CONTROLLER MAIN MENU
  .controller("general.main-menu", ($scope, $window, $location) => {
    $scope.home = () => {
      $location.path('/');
    };

  })
  /// CONTROLLER FOOTER
  .controller("general.footer", ($scope, $http, getApiData) => {
    $scope.sent = false;
    $scope.formcontacto = {
      nombre: "",
      telefono: "",
      email: "",
      consulta: "",
      solicitado: false
    };
    $scope.enviarr = () => {
      console.log($scope.formcontacto);


    }
    $scope.formcontacto.enviar = () => {
      event.preventDefault();
      $scope.sent = true;

      $http
        .post("/api/mailing/suscripcion", {
          nombre: $scope.formcontacto.nombre,
          telefono: $scope.formcontacto.telefono,
          email: $scope.formcontacto.email,
          consulta: $scope.formcontacto.consulta
        })
        .then(
          response => {
            $scope.sent = false;
            $scope.formcontacto.solicitado = true;
            $scope.formcontacto.envioCorrecto = true;
          },
          error => {
            $scope.sent = false;
            $scope.formcontacto.solicitado = true;
            $scope.formcontacto.envioCorrecto = false;
            console.error(error);
          }
        );
    };
    $scope.color = false;
    $scope.imagen = false;
    $scope.posts = false;
    $scope.evento = false;
    //
    getApiData.listaDePost().then(
      ({
        data
      }) => {
        $scope.posts = data;
        // console.log($scope.posts);
        colorCatego();
      },
      error => {
        console.log(error);
      }
    );
    let auxDataColorCategoria = false;
    //
    const colorCatego = () => getApiData.obtenerInfoCategorias().then(
      ({
        data
      }) => {
        auxDataColorCategoria = data;
        auxDataColorCategoria.forEach(v => {
          if ($scope.posts.categoria && $scope.posts.categoria.categoria && v.titulo.toLowerCase() == $scope.posts.categoria.categoria.toLowerCase()) {
            $scope.color = v.color;
            $scope.imagen = v.img;
          } else {
            // console.error('error foreach');
          }
        });

      },
      error => {
        console.log(error);
      }
    );
    //
    getApiData.listaDeEventos().then(
      ({
        data
      }) => {
        $scope.evento = data[0];
      },
      error => {
        console.error(error);
      }
    );
  });
