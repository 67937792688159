angular
  .module("MainApp", [
    "ngRoute",
    "ngTouch",
    "HomeModule",
    "AuthModule",
    "BlogModule",
    "EventosModule",
    "SobreModule",
    "VideosModule",
    "angular-google-analytics"
  ])
  .config(AnalyticsProvider => {
    AnalyticsProvider.setAccount("UA-126805658-1"); //EJ UA-3123123-2
  })
  .controller(
    "mainController",
    ($location, $scope, $rootScope, $timeout, $window, auth, Analytics) => {
      $rootScope.snackbar = mensaje => {
        $rootScope.mensaje = mensaje;
        var snack = angular.element(document.querySelector("#snackbar"));
        snack.addClass("show");
        $timeout(() => {
          snack.removeClass("show");
        }, 3000);
      };
      $rootScope.isMobile = $window.innerWidth <= 992;
      $rootScope.showMenuNavbar = !$rootScope.isMobile;
      $rootScope.toggleMenuNavbarMobile = () => {
        $rootScope.showMenuNavbar = !$rootScope.showMenuNavbar;
      };
      $rootScope.closeMenuNavbarMobile = () => {
        if ($rootScope.isMobile) $rootScope.showMenuNavbar = false;
      };
      angular.element($window).bind("scroll", () => {
        if ($rootScope.isMobile) $rootScope.showMenuNavbar = false;
      });
      angular.element($window).bind("resize", () => {
        $rootScope.isMobile = $window.innerWidth <= 992;
        $rootScope.$$phase || $rootScope.$apply();
      });

      $rootScope.$on("$routeChangeSuccess", () => {
        let bodyClass = "";
        Analytics.trackPage($location.$$path);
        let path = $location.$$path.split("/");

        path.map(tramo => {
          if (tramo !== "") {
            bodyClass += `-${tramo}`;
          }
        });

        $rootScope.bodyClass =
          bodyClass !== "" ? `page${bodyClass}` : "page-home";
      });
    }
  )
  .filter("precio", () => {
    return input => {
      if (input) {
        return parseFloat(input).toFixed(2);
      }
      return "";
    };
  })
  .filter("encodeurl", () => {
    return input => {
      if (input) {
        return window.encodeURIComponent(input);
      }
      return "";
    };
  })
  .filter("removeTrailingSlash", () => {
    return input => {
      if (input) {
        if (input[0] === "/") return input.slice(1);
        else return input;
      }
      return "";
    };
  });
