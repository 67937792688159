let debounced = false;

angular
  .module("HomeModule", [])
  .controller(
    "home.index",
    (
      $scope,
      $window,
      $timeout,
      $http,
      getApiData,
      $sce,
      videoParse,
      $rootScope
    ) => {
      // $scope.linkRetira =
      //   "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-98d4d716-63a6-4a58-9a12-4183563efb14";
      // $scope.linkPagaEnvio =
      //   "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-74fd5ada-693f-4a96-9565-675b805a025a";
      $scope.linkLibroDigital =
        "https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=103718449-c7b0dd71-aa56-463a-8dd5-c1d12ce499a2";
      $scope.botonMP = false;
      $scope.enviando = false;
      $scope.blogs = [];
      $scope.categorias = {};
      $scope.imagenes = [];
      $scope.posteos = [];

      $scope.envioCorrecto = false;
      $scope.envioFallido = false;

      $scope.locked = false;
      $scope.invalidCode = false;
      $scope.correctCode = false;

      $scope.libro = {
        nombre: "",
        apellido: "",
        edad: "",
        email: "",
        codigoDescuento: "",
        retira: "",
      };
      $scope.newsletters = {
        email: "",
        solicitado: false,
      };
      //
      $scope.bloquear = () => {
        $scope.enviando = true;
      };
      //

      //////////

      // $scope.verc = () => {
      //   if (debounced) {
      //     debounced.cancel();
      //   }
      //   if ($scope.libro.codigoDescuento) {
      //     $scope.locked = true;
      //     $scope.invalidCode = false;
      //     $scope.correctCode = false;
      //     debounced = _.debounce(() => {
      //       $http({
      //         method: "POST",
      //         url: "/api/840fc382fe4297c60b813911",
      //         data: {
      //           code: $scope.libro.codigoDescuento
      //         }
      //       }).then(
      //         ({ status }) => {
      //           $timeout(() => {
      //             $scope.locked = false;
      //             $scope.invalidCode = false;
      //             $scope.correctCode = true;
      //             $scope.linkRetira = "http://mpago.la/3MhaE6";
      //             $scope.linkPagaEnvio = "http://mpago.la/3MhaE6";
      //           });
      //         },
      //         error => {
      //           $timeout(() => {
      //             $scope.locked = false;
      //             $scope.invalidCode = true;
      //             $scope.correctCode = false;
      //             $scope.linkRetira =
      //               "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-98d4d716-63a6-4a58-9a12-4183563efb14";
      //             $scope.linkPagaEnvio =
      //               "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-74fd5ada-693f-4a96-9565-675b805a025a";
      //           });
      //         }
      //       );
      //     }, 750);
      //     debounced();
      //   } else {
      //     $scope.locked = false;
      //     $scope.correctCode = false;
      //     $scope.invalidCode = false;
      //     $scope.linkRetira =
      //       "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-98d4d716-63a6-4a58-9a12-4183563efb14";
      //     $scope.linkPagaEnvio =
      //       "https://www.mercadopago.com/mla/checkout/start?pref_id=103718449-74fd5ada-693f-4a96-9565-675b805a025a";
      //   }
      // };

      //////////

      $scope.libro.enviar = (retira) => {
        if (
          (retira === "envio" && $scope.libro.domicilio) ||
          retira === "local" ||
          retira === "digital"
        ) {
          $scope.envioCorrecto = false;
          $scope.envioFallido = false;
          $scope.libro.retira = retira;
          $scope.enviando = true;
          $http
            .post("/api/mailing/comprar", {
              nombre: $scope.libro.nombre,
              apellido: $scope.libro.apellido,
              edad: $scope.libro.edad,
              email: $scope.libro.email,
              domicilio: $scope.libro.domicilio,
              code: $scope.libro.codigoDescuento,
              retira: $scope.libro.retira,
            })
            .then(
              ({ data, status }) => {
                if (status === 200) {
                  $rootScope.snackbar("Email enviado");
                  $scope.enviando = false;
                  $scope.envioCorrecto = true;
                }
              },
              (error) => {
                $rootScope.snackbar("Hubo un error");
                $scope.enviando = false;
                $scope.envioFallido = true;
              }
            );
        }
      };
      //
      Promise.all([
        $http.get("/assets/data/categorias.json").then(
          ({ data }) => {
            $scope.imagenes = data;
          },
          (error) => {
            console.error(error);
          }
        ),
        $http.get("/api/categorias").then(
          ({ data }) => {
            $scope.categorias = data;
          },
          (error) => {
            console.error(error);
          }
        ),
        $http.get("/api/posts").then(
          ({ data }) => {
            $scope.posteos = data;
            console.log(data);
          },
          (error) => {
            console.error(error);
          }
        ),
      ]).then(
        (res) => {
          $scope.categorias.map((categoria, i, a) => {
            categoria.img = $scope.imagenes[i].img;
            categoria.color = $scope.imagenes[i].color;
            categoria.pubdate = null;
            categoria.posteo = null;
            $scope.posteos.map((posteo) => {
              if (posteo.categoria._id === categoria._id) {
                categoria.posteo = posteo;
                if (categoria.pubdate) {
                  if (new Date(posteo.pubdate) > new Date(categoria.pubdate)) {
                    categoria.pubdate = posteo.pubdate;
                    categoria.titulo = posteo.titulo;
                    categoria.pubdate = posteo.pubdate;
                    categoria.blog = posteo._id;
                    categoria.posteo = posteo;
                    if (posteo.video) {
                      posteo.video = videoParse.sofparser(posteo.video);
                    }
                  }
                } else {
                  categoria.pubdate = posteo.pubdate;
                  categoria.titulo = posteo.titulo;
                  categoria.pubdate = posteo.pubdate;
                  categoria.blog = posteo._id;
                  categoria.posteo = posteo;
                  if (posteo.video) {
                    posteo.video = videoParse.sofparser(posteo.video);
                  }
                }
              }
            });
            $scope.blogs.push(categoria.posteo);
          });
          $scope.$apply();
        },
        (error) => {
          console.error;
        }
      );
      //
      $scope.colores = [
        "#008080",
        "#0000FF",
        "#880088",
        "#adff2f",
        "#ffa500",
        "#ff0000",
        "#14AECF",
        "#ffff00",
      ];

      $scope.newsletters.enviar = () => {
        $http
          .post("/api/mailing/newsletters", {
            email: $scope.newsletters.email,
          })
          .then(
            (response) => {
              $scope.newsletters.solicitado = true;
              $scope.newsletters.envioCorrecto = true;
              // console.log(response);
            },
            (error) => {
              $scope.newsletters.solicitado = true;
              $scope.newsletters.envioCorrecto = false;
              console.error(error);
            }
          );
      };

      // $timeout(() => {
      //   let videoLibro = document.querySelector("#videolibro");
      //   const mediaSource = new MediaSource();
      //   const url = URL.createObjectURL(mediaSource);
      //   videoLibro.src = url;
      //   $timeout(() => {
      //     const videoSourceBuffer = mediaSource.addSourceBuffer('video/mp4;codecs="H264"');
      //     fetch("/assets/videos/PROMO.mp4").then(function (response) {
      //       // The data has to be a JavaScript ArrayBuffer
      //       return response.arrayBuffer();
      //     }).then(function (videoData) {
      //       videoSourceBuffer.appendBuffer(videoData);
      //     });
      //   }, 1000)
      // }, 100);
    }
  );
