angular.module("EventosModule", []).controller("eventos.index", ($scope, $http, getApiData) => {


    $scope.title = "eventos";
    $scope.eventos = [];
    getApiData.listaDeEventos().then(
        response => {
            $scope.eventos = response.data;
            
        },
        error => {
            console.log(error);
        }
    );
});
