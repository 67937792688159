angular
  .module("MainApp")
  .directive("mainMenu", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/main-menu.html",
      controller: "general.main-menu"
    };
  })
  .directive("footerSection", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/footer-section.html",
      controller: "general.footer"
    };
  })
  .directive("subscribeNewsletter", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/subscribe-newsletter.html",
      controller: "general.newsletter"
    }
  })
  .directive("subscribeEvents", () => {
    return {
      restrict: "E",
      templateUrl: "/views/general/subscribe-events.html",
      controller: "general.events"
    }
  })
  .directive("blogContent", () => {
    return {
      restrict: "E",
      scope: {
        posts: "="
      },
      templateUrl: "/views/blog/content.html",
      controller: "blog.index"
    };
  })
  .directive("blogCard", () => {
    return {
      restrict: "E",
      scope: {
        post: "="
      },
      templateUrl: "/views/blog/card.html",
      controller: "blog.index"
    };
  })

  .directive("loadFrame", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $("document").ready(() => {
          $(elm).attr("src", attrs.source);
        });
      }
    };
  })
  .directive("scrollRight", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $(attrs.target).animate({
              scrollLeft: "+=" + $(attrs.target).width()
            },
            parseInt(attrs.time)
          );
        });
      }
    };
  })
  .directive("scrollLeft", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $(attrs.target).animate({
              scrollLeft: "-=" + $(attrs.target).width()
            },
            parseInt(attrs.time)
          );
        });
      }
    };
  })
  .directive("scrollToContacto", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $("body, html").animate({
              scrollTop: $("footer-section").offset().top
            },
            500
          );
        });
      }
    };
  })
  .directive("scrollToTarget", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $("body, html").animate({
              scrollTop: $(attrs.target).offset().top
            },
            700
          );
        });
      }
    };
  })
  .directive("escondeMenu", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(elm).on("click", () => {
          $("#navbar").collapse("hide");
        });
      }
    };
  })
  .directive("flotante", () => {
    return {
      restrict: "A",
      link: (scope, elm, attrs) => {
        $(window).on("scroll", e => {
          if ($(window).scrollTop() > 92) {
            if ($(window).width() >= 992) {
              $(elm).addClass("flotante");
            }
          } else {
            $(elm).removeClass("flotante");
          }
        });
      }
    };
  });
/**.directive("flotante", ($rootScope, $timeout) => {
  return {
    restrict: "A",
    link: (scope, elm, attrs) => {
      $(window).on("scroll", e => {
        if ($(window).scrollTop() < 50) {
          if ($(window).width() >= 992) {
            $(elm).addClass("enlarged");
            elm[0].children[0].src = "assets/images/environmental-brand-logo.png";
            angular.element(elm[0].children[0]).removeClass('brand-logo');
          }
        } else {
          $(elm).removeClass("enlarged");
          elm[0].children[0].src = "assets/images/environmental-brand-logo-shrinked.png";
          angular.element(elm[0].children[0]).addClass('brand-logo');
        }
      });
    }
  };
}) */