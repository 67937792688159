angular.module("MainApp").factory("videoParse",()=>{
    const embedLink = v=>{
        return 'https://www.youtube.com/embed/'+v;
    };
    const parse = url=>{
        return (url.split('/'))[3];
    };
    const youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    };
    const parseArray = (array) =>{
        array.map(posteo => {
            posteo.video = videoParse.sofparser(posteo.video);
        });
    };
    return {
        embeded:(v)=>embedLink(v),
        parser:(url)=>parse(url),
        sofparser:(url)=>youtube_parser(url),
        parseArray:(array)=>parseArray(array)
    }
});