angular.module("MainApp").factory("getApiData", ($http, $rootScope) => {
  const getter = url => {
    return $http({ method: "GET", url: url });
  };
  const putter = (url,body) => {
    return $http.put(url,body);
  };
  const poster = (url, body)=>{
    return $http.post(url,body);
  }
  return {
    listaDePost:()=>getter('/api/posts'),
    listaDePostPorTag:(tag)=>getter('api/posts/tags/'+tag),
    listaDePostPorCategoria:(categoria)=>getter('api/posts/categoria/lista/'+categoria),
    getUnPostCategoria:(id)=>getter('api/posts/categoria/'+id),
    obtenerPost:(id)=>getter('/api/posts/'+id),
    obtenerCategoria:(id)=>getter('/api/categorias/'+id),
    obtenerCategorias:()=>getter('api/categorias'),
    agregarComentario:(comentario,id)=>putter('/api/posts/comentar/'+id, comentario),
    listaDeEventos:()=>getter('/api/eventos'),
    obtenerEvento:(id)=>getter('/api/eventos/'+id),
    enviarMailEventos:(email)=>poster('/api/mailing/inscripcion',email),
    enviarMailSuscripcion:(email)=>poster('/api/mailing/suscripcion',email),
    obtenerInfoCategorias:()=>getter('/assets/data/categorias.json')
  };
});
