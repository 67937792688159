angular.module("VideosModule", []).controller("videos.index", ($scope, $http, $sce, videoParse) => {
    $scope.quantity=4;
    $scope.postVideos = [];
    $scope.title = "videos";
    $scope.indice = 0;
    $scope.cantPaginas=0;

    $http.get('/api/posts').then(
        ({
            data
        }) => {
            $scope.postVideos = data;
            $scope.postVideos.map(posteo => {
                posteo.video = videoParse.sofparser(posteo.video);
                posteo.video = $sce.trustAsResourceUrl("https://www.youtube.com/embed/" + posteo.video);

            });
            // $scope.postVideos.sort(
            //     (post, anterior)=>post.pubdate<anterior
            // )
        },
        error => {
            console.log(error);
        }
    );
    let aplicarPagina = () => {
        $scope.indice = $scope.pagina * $scope.quantity;
    };
    //
    $scope.paginaMenos = () => {
        $scope.cantPaginas = Math.ceil($scope.posts.length !== 0 ? $scope.posts.length / $scope.quantity : 0);
        if ($scope.pagina > 0)
            $scope.pagina -= 1;
        else
            $scope.pagina = 0;
        aplicarPagina();
    }
    //
    $scope.paginaMas = () => {
        $scope.cantPaginas = Math.ceil($scope.posts.length !== 0 ? $scope.posts.length / $scope.quantity : 0);
        if ($scope.pagina + 1 < $scope.cantPaginas)
            $scope.pagina += 1;
        aplicarPagina();
    };
}); 